import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import { useState, useEffect } from "react";
import { isNaturalLakeGroup, isBigFiveGroup, isRhinelanderGroup, isKingsGroup, isDuBayGroup, isTemperatureGroup, isPrecipitationGroup } from "../Helpers/groupCheck"; // Import all helper functions

//Add Check to put only buttons the User has access too
export const LandingPage = () => {

  const [user, setUser] = useState(null);
  useEffect(() => {
    const userObj = JSON.parse(window.localStorage.getItem("afs_user"));
    // console.log("userObj", userObj);

    if (userObj) {
      setUser(userObj);
    }
  }, []);

  return (
    <ProjectLayout>
            <Paper
            sx={{
                p: 2,
                margin: "auto",
                marginTop: "5%",
                maxWidth: 400,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
            >
                {/* <Button size='large' href="/natural-lakes">Natural Lakes Data Entry</Button>
                <Button size='large' href="/big-five">Big Five Data Entry</Button> */}
                {/* Natural Lakes */}
                {user?.groups.some(group => isNaturalLakeGroup(group.name)) && (
                  <Button size='large' href="/natural-lakes">Enter Natural Lakes Data</Button>
                )}               
                {user?.groups.some(group => isBigFiveGroup(group.name)) && (
                  <Button size='large' href="/big-five">Enter Big 5 Data</Button>
                )}                
                {user?.groups.some(group => isRhinelanderGroup(group.name)) && (
                  <Button size='large' href="/rhinelander">Enter Rhinelander Data</Button>
                )}               
                {user?.groups.some(group => isKingsGroup(group.name)) && (
                  <Button size='large' href="/kings">Enter Kings Data</Button>
                )}
                {user?.groups.some(group => isDuBayGroup(group.name)) && (
                  <Button size='large' href="/dubay">Enter DuBay Data</Button>
                )}
                {user?.groups.some(group => isTemperatureGroup(group.name)) && (
                  <Button size='large' href="/temperature">Enter Temperature Data</Button>
                )}
                {user?.groups.some(group => isPrecipitationGroup(group.name)) && (
                  <Button size='large' href="/precipitation">Enter Precipitation Data</Button>
                )}
                {/* <Button size='large' href="/du bay">Du Bay Data Entry</Button> */}
                {/* <Button size='large' href="/petenwell-castlerock">Petenwell Castlerock Data Entry</Button> */}
            </Paper>

    </ProjectLayout>
 );
};
