//Probably should have split this up for Big5 & Natural Lakes, Kings, Rhinelander.
export function generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits) {
  let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?>
  <TimeSeries xmlns="http://www.wldelft.nl/fews/PI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.wldelft.nl/fews/PI http://fews.wldelft.nl/schemas/version1.0/pi-schemas/pi_timeseries.xsd" version="1.12">`;

// Adjust time
  const isDaylightSavings = (date) => {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(january, july) !== date.getTimezoneOffset();
  };

  const timeZone = isDaylightSavings(new Date()) ? -5.0 : -6.0;
  xmlBodyStr += `<timeZone>${timeZone}</timeZone>`;

  //Iterate through data
  for (const [Id, { paramId, eventValue, eventUnit, eventQualifierId,expectedEventDate }] of Object.entries(eventValuesIdsUnits)) {
    if (Id !== "undefined") { 
      xmlBodyStr += `
      <series> 
        <header>
          <type>instantaneous</type>
          <moduleInstanceId>DamTender_WebApp</moduleInstanceId>
          <locationId>${locationId}</locationId>
          <parameterId>${paramId}</parameterId>
          <qualifierId>DamTender</qualifierId>`; 
      
      // Add optional eventQualifierId if present
      if (eventQualifierId) {
        xmlBodyStr += `
          <qualifierId>${eventQualifierId}</qualifierId>`;
      }

      // Add Expected qualifierId where needed
       if (Id.includes("Expected")) {
        xmlBodyStr += `
          <qualifierId>Expected</qualifierId>`;
      } 
      
      // Handle Kings Arrays
      if(locationId == "KIN")
      {
        xmlBodyStr += `
        <timeStep unit="nonequidistant"/>
        <startDate date="${eventDateXML[0]}" time="${eventTimeXML[0]}"/>
        <endDate date="${eventDateXML[0]}" time="${eventTimeXML[0]}"/>
        <missVal>-999.0</missVal>
        <stationName>${locationId}</stationName>
        <units>${eventUnit}</units>
        <creationDate>${eventDateXML[0]}</creationDate>
        <creationTime>${eventTimeXML[0]}</creationTime>
      </header>`

      for (let i = 0; i < eventValue.length; i++) {
      xmlBodyStr += `
      <event date="${expectedEventDate ? expectedEventDate : eventDateXML[i]}" time="${eventTimeXML[i]}" value="${eventValue[i]}" flag="0"/>`
      }
    }
      //All other locations
      else
      {
        xmlBodyStr += `
        <timeStep unit="nonequidistant"/>
        <startDate date="${eventDateXML}" time="${eventTimeXML}"/>
        <endDate date="${eventDateXML}" time="${eventTimeXML}"/>
        <missVal>-999.0</missVal>
        <stationName>${locationId}</stationName>
        <units>${eventUnit}</units>
        <creationDate>${eventDateXML}</creationDate>
        <creationTime>${eventTimeXML}</creationTime>
      </header>
      <event date="${expectedEventDate ? expectedEventDate : eventDateXML}" time="${eventTimeXML}" value="${eventValue}" flag="0"/>`;
      }
      
      xmlBodyStr += `
    </series>`;
    }
  }

  xmlBodyStr += `
  </TimeSeries>`;

  return xmlBodyStr;
}

  

export function myCustomEncoder(unsafe) {
    unsafe = unsafe.replace(/\s+/g, '%20');
    return unsafe.replace(/[<>&'"]/g , function (c) {
        switch (c) {
            case '<': return '%3C';
            case '>': return '%3E';
            case '?': return '%3F';
            case '=': return '%3D';
            case '"': return '%22';
            case '/g': return '%20';
            case '/t': return '%20';        
        }
    });
  }


export  async function handleRunTask(workflowId,accessToken) {

  const url = `/proxy/FewsWebServices/rest/fewspiservice/v1/runtask` +
      `?workflowId=${encodeURIComponent(workflowId)}`;
      //  +
      // `&startTime=${encodeURIComponent(startTime)}` +
      // `&endTime=${encodeURIComponent(endTime)}` +
      // `&timeZero=${encodeURIComponent(timeZero)}`;

  const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${accessToken}`
      }
  });

  if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to run task:', response.status, errorText);
      throw new Error(`Failed to run task: ${response.status} - ${errorText}`);
  }
  const result = await response.text();
}