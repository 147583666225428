import * as React from 'react';
import { useState , useEffect } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '../../Utils/MyAdapterDayJs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import { myCustomEncoder , generateXmlBodyStr } from './CustomEncoder';
import valid_input_precipitation from './valid_input_precipitation';
import { useParams } from "react-router-dom";


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const Precipitation = () => {
  // USERNAME
  const { user, token } = useAuth();
  const { precipLocation } = useParams();

  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];
  
  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    return formattedDate
  };
  const dateNowParser = (nowDateString) => {
    const nowFormattedDate = nowDateString.format('YYYY-MM-DD');
    return nowFormattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    return formattedTime
  };
  const timeNowParser = (dateString) => {
    const nowFormattedTime = dateString.format('HH:00:00');
    return nowFormattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  
  const [locationId, setLocationId] = useState(precipLocation);
  const [paramId, setParamId] = useState('T');
  const [nowDate, setNowDate] = useState(dateNowParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeNowParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS
  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:mm:00') : null; //'HH:mm:00'
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');
  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');


  // VALIDATION LOGIC
  const input_ranges = valid_input_precipitation[locationId];
  const display_name = input_ranges.DisplayName;

  const include_precip = input_ranges.Precipitation;
  const min_precip = input_ranges.MinPrecip;
  const max_precip = input_ranges.MaxPrecip;
  const precip_id = input_ranges.PrecipId;
  const precip_unit = input_ranges.PrecipUnit;
  const precip_parameter_id = input_ranges.PrecipParameterId;

  const include_snow_depth = input_ranges.SnowDepth;
  const snow_depth_description = input_ranges.SnowDepthDisplayName+ " (IN)";
  const snow_depth_min = input_ranges.MinSnowDepth;
  const snow_depth_max = input_ranges.MaxSnowDepth;
  const snow_depth_default = input_ranges.DefaultSnowDepth;
  const snow_depth_id = input_ranges.SnowDepthId;
  const snow_depth_unit = input_ranges.SnowDepthUnit;
  const snow_depth_qualifier_id = input_ranges.SnowDepthQualifierId;
  const snow_depth_parameter_id = input_ranges.SnowDepthParameterId;

  const include_new_snow = input_ranges.NewSnow;
  const new_snow_description = input_ranges.NewSnowDisplayName+ " (IN)";
  const new_snow_min = input_ranges.MinNewSnow;
  const new_snow_max = input_ranges.MaxNewSnow;
  const new_snow_default = input_ranges.DefaultNewSnow;
  const new_snow_id = input_ranges.NewSnowId;
  const new_snow_unit = input_ranges.NewSnowUnit;
  const new_snow_qualifier_id = input_ranges.NewSnowQualifierId;
  const new_snow_parameter_id = input_ranges.NewSnowParameterId;

  const default_precip = input_ranges.DefaultPrecip;
  const default_new_snow = input_ranges.DefaultNewSnow;
  const default_snow_depth = input_ranges.DefaultSnowDepth;
  // SET DEFAULT VALUES
  const [precipValue, setPrecipValue] = useState(default_precip);
  const [newSnowValue, setNewSnowValue] = useState(new_snow_default);
  const [snowDepthValue, setSnowDepthValue] = useState(snow_depth_default);
  // Precipitation in mm in FEWS
  function inchesToMillimeters(inches) {
    const millimeters = inches * 25.4;
    return millimeters;
  }
  const [precipMMValue, setPrecipMMValue] = useState(inchesToMillimeters(default_precip));
  const [newSnowMMValue, setNewSnowMMValue] = useState(inchesToMillimeters(new_snow_default));
  const [snowDepthMMValue, setSnowDepthMMValue] = useState(inchesToMillimeters(snow_depth_default));

  useEffect(() => {
    setPrecipMMValue(inchesToMillimeters(precipValue));
  }, [precipValue]);
  useEffect(() => {
    setNewSnowMMValue(inchesToMillimeters(newSnowValue));
  }, [newSnowValue]);
  useEffect(() => {
    setSnowDepthMMValue(inchesToMillimeters(snowDepthValue));
  }, [snowDepthValue]);

  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const precip_tooltip = `Value must be between ${min_precip} and ${max_precip} Inches.`;
  const new_snow_tooltip = `Value must be between ${new_snow_min} and ${new_snow_max} Inches.`;
  const snow_depth_tooltip = `Value must be between ${snow_depth_min} and ${snow_depth_max} Inches.`;

//Aggregated for XML creation 
const eventValuesIdsUnits = {
  [precip_id]: { paramId: precip_parameter_id, eventValue: precipMMValue, eventUnit: precip_unit },
  [new_snow_id]: { paramId: new_snow_parameter_id, eventValue: newSnowMMValue, eventUnit: new_snow_unit },
  [snow_depth_id]: { paramId: snow_depth_parameter_id, eventValue: snowDepthMMValue, eventUnit: snow_depth_unit }
};

  
const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);

console.log(xmlBodyStr);

//POST
function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
    const MYPOSTURL = `/proxy/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;

    const accessToken = JSON.parse(token).access;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${accessToken}`
        },
        body: postData
    };
 
    async function fetchData() {
      try {
        const response = await fetch( MYPOSTURL, requestOptions );
       // console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
        // console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted", precipValue, eventDate);
        } 
      } catch (error) {
          // console.log(error);
          setError("ERROR:",error,". Please try again later.");
      }
    }
    fetchData();
    // handleOpen();
  }


  return(
    <ProjectLayout>
      <div>
          <Paper
            sx={{
                p: 2,
                margin: "auto",
                marginTop: "5%",
                maxWidth: 400,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
            >
    <h1 align='center'>{display_name} Precipitation Entry</h1>
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" align='center'>
        <Grid item>
          {/* USERNAME: AUTOPOPULATED */}
          <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 350}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
            {/* DATE */}
            <Tooltip title="Date recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Recorded"
                      disableFuture
                      minDate={minDateValidation}
                      value={eventDate}
                      // placeholderText="Click to select a date"
                      // dateFormat="DD-MM-YYYY"
                      onChange={e=>handleDateChange(e)} 
                    /> 
                  </LocalizationProvider>
                </FormControl>
            </Tooltip>
            {/* TIME */}
            <Tooltip title="Time recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker  
                    label="Time Recorded"
                    value={eventTime}
                    onChange={e=>handleTimeChange(e)} 
                    format='HH:mm:00'
                  />
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* PRECIPITATION */}
            <Tooltip title={precip_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Precipitation (IN)"
                    variant="outlined"
                    fullWidth
                    value={precipValue}
                    onInput={ e=>setPrecipValue(e.target.value)}
                    error={(precipValue < min_precip) || (precipValue >= max_precip)}
                    type='number'
                    inputProps={{
                      min: min_precip, 
                      step: 0.01,
                      max: max_precip
                    }}
                  />
              </FormControl>
            </Tooltip>

            {/* NEW SNOW */}
            {include_new_snow &&
            <Tooltip title={new_snow_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label={new_snow_description}
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={newSnowValue}
                    onInput={ e=>setNewSnowValue(e.target.value)}
                    // error={hasError}
                    error={(newSnowValue < new_snow_min) || (newSnowValue >= new_snow_max)}
                    inputProps={{
                      min: new_snow_min, 
                      step: 0.01,
                      max: new_snow_max
                    }}
                  />
              </FormControl>
            </Tooltip>
          }

            {/* SNOW DEPTH*/}
            {include_snow_depth &&
            <Tooltip title={snow_depth_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label={snow_depth_description}
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={snowDepthValue}
                    onInput={ e=>setSnowDepthValue(e.target.value)}
                    // error={hasError}
                    error={(snowDepthValue < snow_depth_min) || (snowDepthValue >= snow_depth_max)}
                    inputProps={{
                      min: snow_depth_min, 
                      step: 0.01,
                      max: snow_depth_max
                    }}
                  />
              </FormControl>
            </Tooltip>
          }

            {/* SUBMIT */}
              <Button  sx={{m: 1,  mt: 4, width: 350}}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  margin="normal"
                  onClick={handleSubmit}
                  disabled={!eventDate}
                  >
                    Submit
              </Button>
              <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Submitted
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                  </Typography>
                </Box>
              </Modal>
            </div>
        
          </Grid>
        </Grid>
         
        </form>
        {error && (<h3 className="error" align="center"> {error} </h3>)}
        {message && (<h3 align="center"> {message} </h3>)}
    </Paper>
    </div>
    </ProjectLayout>
  );
};
