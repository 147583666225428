// Define sets of group names for Natural Lakes and Big Five
const naturalLakesGroups = new Set([
    "LVD", "TWN", "BUK", "DEE", "LDS", "SMI", "NMI", "BUR", 
    "SCP", "LSG", "BSG", "SPN", "NPN", "MIN", "SQL", "PCL", "GIL"
  ]);
  
  const bigFiveGroups = new Set([
    "RAI", "RIC", "SPD", "WOW", "EP3"
  ]);
  
  const rhinelanderGroups = new Set([
    "RPC"
  ]);

  const kingsGroups = new Set([
    "KIN"
  ]);
  const dubayGroups = new Set([
    "DAY"
  ]);

  const temperatureGroups = new Set([
    "ROT","GDM","RPC"
  ]);

  const precipitationGroups = new Set([
    "EP3","WOW","RIC","WR1"
  ]);

  export const isNaturalLakeGroup = (groupName) => {
    return naturalLakesGroups.has(groupName);
  };
  
  export const isBigFiveGroup = (groupName) => {
    return bigFiveGroups.has(groupName);
  };

  export const isRhinelanderGroup = (groupName) => {
    return rhinelanderGroups.has(groupName);
  };
  
  export const isKingsGroup = (groupName) => {
    return kingsGroups.has(groupName);
  };
  export const isDuBayGroup = (groupName) => {
    return dubayGroups.has(groupName);
  };

  export const isTemperatureGroup = (groupName) => {
    return temperatureGroups.has(groupName);
  };

  export const isPrecipitationGroup = (groupName) => {
    return precipitationGroups.has(groupName);
  };

