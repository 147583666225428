import * as React from 'react';
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_dubay from './valid_input_dubay';
import { myCustomEncoder, generateXmlBodyStr } from './CustomEncoder';


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const DuBay = () => {
  // USERNAME
  const { user, token } = useAuth();
  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];

  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    return formattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    return formattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
    setExpectedEventDate(event.add(0, 'day'));
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  const [locationId, setLocationId] = useState('DAY');
  const [paramId, setParamId] = useState('HW');
  const [nowDate, setNowDate] = useState(dateParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [expectedEventDate, setExpectedEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS

  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const expectedEventDateXML = expectedEventDate ? expectedEventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:mm:00') : null; //'HH:mm:00'


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');

  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');

  // VALIDATION LOGIC
  const input_ranges = valid_input_dubay[locationId];
  const display_name = input_ranges.DisplayName;
 
  const min_totalflow = input_ranges.MinTotalFlow;
  const max_totalflow = input_ranges.MaxTotalFlow;
  const default_totalflow = input_ranges.DefaultTotalFlow;
  const flow_id = input_ranges.FlowId;
  const expected_flow_id = input_ranges.ExpectedTotalFlowId;
  const flow_unit = input_ranges.FlowUnit;

  const flow_parameter_id = input_ranges.FlowParameterId;
  const expected_total_flow_qualifier_id = input_ranges.ExpectedTotalFlowQualifierId;

  // SET DEFAULT VALUES
  const [totalFlowValue, setTotalFlowValue] = useState(default_totalflow);
  const [expectedTotalFlowValue, setExpectedTotalFlowValue] = useState(default_totalflow);

  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const totalflow_tooltip = `Value must be between ${min_totalflow} and ${max_totalflow} (CFS?)`;

  //Aggregated for XML creation 
  const eventValuesIdsUnits = {
    [expected_flow_id]: { paramId: flow_parameter_id, eventValue: expectedTotalFlowValue, eventUnit: flow_unit ,  expectedEventDate: expectedEventDateXML },
  }

  const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
  const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);


  function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
 
    const MYPOSTURL = `/proxy/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;


    const accessToken = JSON.parse(token).access;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${accessToken}`
        },
        body: postData
    };
    
    async function fetchData() {
      try {
        const response = await fetch(MYPOSTURL, requestOptions);
        //console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
       // console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted");
        }
      } catch (error) {
        //console.log(error);
        setError("ERROR:", error, ". Please try again later.");
      }
    }
    fetchData();
    //handleOpen();
  }

  return (
    <ProjectLayout>
      <Paper
        sx={{
            p: 2,
            margin: "auto",
            marginTop: "5%",
            maxWidth: 400,
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
        }}
        >
        <h1 align='center'>{display_name} Data Entry</h1>
        <form onSubmit={handleSubmit}>

        <Grid container justifyContent="center" align='center'>
          <Grid item>
            {/* USERNAME: AUTOPOPULATED */}
            <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 350}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
 
            {/* DATE */}
            <Tooltip title="Date recorded (required)" placement="right">
              <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date Recorded"
                    disableFuture
                    minDate={minDateValidation}
                    value={eventDate}
                    dateFormat="MM-DD-YYYY" 
                    format="MM-DD-YYYY"
                    onChange={e=>handleDateChange(e)} 
                  /> 
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* TIME */}
            <Tooltip title="Time recorded (required)" placement="right">
              <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker  
                    label="Time Recorded"
                    value={eventTime}
                    onChange={e=>handleTimeChange(e)} 
                    format='HH:mm:00'
                  />
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
              {/* EXPECTED TOTAL FLOW  */}
              <Tooltip title={totalflow_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Total Expected Flow (CFS)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={expectedTotalFlowValue}
                    onInput={e => setExpectedTotalFlowValue(e.target.value)}
                    error={(totalFlowValue < min_totalflow) || (totalFlowValue >= max_totalflow)}
                    inputProps={{
                      min: min_totalflow,
                      step: 0.01,
                      max: max_totalflow
                    }}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: -3 }}>
            <Grid item>
              {/* SUBMIT */}
            <Button  sx={{m: 1,  mt: 4, width: 350}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              margin="normal"
              onClick={handleSubmit}
              disabled={!eventDate}
              >
                  Submit
            </Button>  
              <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Submitted
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                  </Typography>
                </Box>
              </Modal>
            </div>



            </Grid>
          </Grid>


        </form>
        {error && (<h3 className="error" align="center"> {error} </h3>)}
        {message && (<h3 align="center"> {message} </h3>)}
      </Paper>
    </ProjectLayout>
  );
};
