export default {
    "KIN": {       
        "DisplayName": "Kings",
        
        "MinHead": 10,
        "MaxHead": 90,
        "DefaultHead": 0,
        "HeadId": "HeadWater",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        
        "MinTurbineFlow": 0,
        "MaxTurbineFlow": 1000,
        "DefaultTurbineFlow": 0,
        "TurbineId": "Turbines",
        "TurbineParameterId": "QG",
        "TurbineUnit": "CFS",
        //"TurbineQualifierId": "Turbine_Total",

        "MinGate1": 0,
        "MaxGate1": 100,
        "DefaultGate1": 0,
        "Gate1Id": "Gate_1",
        "Gate1Unit": "IN",
        "Gate1ParameterId": "NO",
        "Gate1QualifierId": "Gate_1",

        "MinGate2": 0,
        "MaxGate2": 100,
        "DefaultGate2": 0,
        "Gate2Id": "Gate_2",
        "Gate2Unit": "IN",
        "Gate2ParameterId": "NO",
        "Gate2QualifierId": "Gate_2",

        "MinGate3": 0,
        "MaxGate3": 100,
        "DefaultGate3": 0,
        "Gate3Id": "Gate_3",
        "Gate3Unit": "IN",
        "Gate3ParameterId": "NO",
        "Gate3QualifierId": "Gate_3",

        "MinGateFlow": 0,
        "MaxGateFlow": 1000,
        "DefaultGateFlow": 100,
        "GateFlowId": "QU",  //<!--Discharge, controlled by regulating outlet-->
        "GateFlowUnit": "CFS",

        "MinTotalFlow": 0,
        "MaxTotalFlow": 100000,
        "DefaultTotalFlow": 0,
        "TotalFlowId": "Total_Flow", 
        "TotalFlowParameterId": "QT", 
        "TotalFlowUnit": "CFS",
        "TotalFlowQualifierId": "Expected"
    }
}
