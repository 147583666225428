import * as React from 'react';
import { useState, useEffect } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '../../Utils/MyAdapterDayJs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_kings from './valid_input_kings';
import { myCustomEncoder, generateXmlBodyStr, handleRunTask } from './CustomEncoder';


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const Kings = () => {
  // USERNAME
  const { user, token } = useAuth();
  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];

  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    return formattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    return formattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  const [locationId, setLocationId] = useState('KIN');
  const [paramId, setParamId] = useState('HW');
  const [nowDate, setNowDate] = useState(dateParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(dayjs()); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS
  //const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  //const eventTimeXML = eventTime ? eventTime.format('HH:00:00') : null; //'HH:mm:00'

  //Time Arrays
  const arrayLength = 12;
  const eventDateArray = Array.from({ length: arrayLength }, (_, index) => 
      dayjs(eventDate).set('hour', 9).set('minute', 0).add(index * 2, 'hour').add(-1,'day')
    );
  const eventTimeArray = Array.from({ length: arrayLength }, (_, index) => 
      dayjs(eventDate).set('hour', 9).set('minute', 0).add(index * 2, 'hour').add(-1,'day')
    );  
  const eventDateXML = eventDateArray.map(date => date.format('YYYY-MM-DD'));
  const eventTimeXML = eventDateArray.map(date => date.format('HH:00:00'));



  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');

  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');


  // VALIDATION LOGIC
  const input_ranges = valid_input_kings[locationId];
  const display_name = input_ranges.DisplayName;
  const min_head = input_ranges.MinHead;
  const max_head = input_ranges.MaxHead;
  const default_head = input_ranges.DefaultHead;
  const min_turbineflow = input_ranges.MinTurbineFlow;
  const max_turbineflow = input_ranges.MaxTurbineFlow;
  const default_turbineflow = input_ranges.DefaultTurbineFlow;
  const min_gate1 = input_ranges.MinGate1;
  const max_gate1 = input_ranges.MaxGate1;
  const default_gate1 = input_ranges.DefaultGate1;
  const min_gate2 = input_ranges.MinGate2;
  const max_gate2 = input_ranges.MaxGate2;
  const default_gate2 = input_ranges.DefaultGate2;
  const min_gate3 = input_ranges.MinGate3;
  const max_gate3 = input_ranges.MaxGate3;
  const default_gate3 = input_ranges.DefaultGate3;
  const min_gateflow = input_ranges.MinGateFlow;
  const max_gateflow = input_ranges.MaxGateFlow;
  const min_totalflow = input_ranges.MinTotalFlow;
  const max_totalflow = input_ranges.MaxTotalFlow;

  const default_gateflow = input_ranges.DefaultGateFlow;
  const default_totalflow = input_ranges.DefaultTotalFlow;

  const head_id = input_ranges.HeadId;
  const turbine_id = input_ranges.TurbineId;
  const gate1_id = input_ranges.Gate1Id;
  const gate2_id = input_ranges.Gate2Id;
  const gate3_id = input_ranges.Gate3Id;
  const gate_flow_id = input_ranges.GateFlowId;
  const total_flow_id = input_ranges.TotalFlowId;

  const head_unit = input_ranges.HeadUnit;
  const turbine_unit = input_ranges.TurbineUnit;
  const gate1_unit = input_ranges.Gate1Unit;
  const gate2_unit = input_ranges.Gate2Unit;
  const gate3_unit = input_ranges.Gate3Unit;
  const gate_flow_unit = input_ranges.GateFlowUnit;
  const total_flow_unit = input_ranges.TotalFlowUnit;

  const head_qualifier_id = input_ranges.HeadQualifierId;
  const turbine_qualifier_id = input_ranges.TurbineQualifierId;
  const gate1_qualifier_id = input_ranges.Gate1QualifierId;
  const gate2_qualifier_id = input_ranges.Gate2QualifierId;
  const gate3_qualifier_id = input_ranges.Gate3QualifierId;
  const gate_flow_qualifier_id = input_ranges.GateFlowQualifierId;
  const total_flow_qualifier_id = input_ranges.TotalFlowQualifierId;

  const head_parameter_id = input_ranges.HeadParameterId;
  const turbine_parameter_id = input_ranges.TurbineParameterId;
  const gate1_parameter_id = input_ranges.Gate1ParameterId;
  const gate2_parameter_id = input_ranges.Gate2ParameterId;
  const gate3_parameter_id = input_ranges.Gate3ParameterId;
  const gate_flow_parameter_id = input_ranges.GateFlowParameterId;
  const total_flow_parameter_id = input_ranges.TotalFlowParameterId;

  // SET APPEND VALUES TO STATE ARRAYS
  const updateArrayValue = (setStateFunc, index, newValue) => {
    setStateFunc((prevValues) => {
      const updatedValues = [...prevValues];  // Create a copy of the existing array
  
      // Ensure the array has enough space for the new value at the specified index
      while (updatedValues.length <= index) {
        updatedValues.push(null);  // Fill with `null` or any placeholder value
      }
  
      updatedValues[index] = newValue;  // Insert the new value at the specified index
      return updatedValues;  // Return the updated array
    });
    ;
  };

  const [headValue, setHeadValue]               = useState(Array(arrayLength).fill(default_head));
  const [turbineflowValue, setTurbineFlowValue] = useState(Array(arrayLength).fill(default_turbineflow));
  const [gate1Value, setGate1Value] = useState(Array(arrayLength).fill(default_gate1));
  const [gate2Value, setGate2Value] = useState(Array(arrayLength).fill(default_gate2));
  const [gate3Value, setGate3Value] = useState(Array(arrayLength).fill(default_gate3));
  const [gateFlowValue, setGateFlowValue] = useState(Array(arrayLength).fill(default_gateflow));
  const [totalFlowValue, setTotalFlowValue] = useState(Array(arrayLength).fill(-999));
  useEffect(() => {
    setTotalFlowValue((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[updatedValues.length - 1] = default_totalflow;
      return updatedValues;
    });
  }, []);

  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const head_tooltip = `Value must be between ${min_head} and ${max_head} (FT) (required).`;
  const turbineflow_tooltip = `Value must be between ${min_turbineflow} and ${max_turbineflow} (cfs)`;
  const gate1_tooltip = `Value must be between ${min_gate1} and ${max_gate1} (IN)`;
  const gate2_tooltip = `Value must be between ${min_gate2} and ${max_gate2} (IN)`;
  const gate3_tooltip = `Value must be between ${min_gate3} and ${max_gate3} (IN)`;
  const gateflow_tooltip = `Value must be between ${min_gateflow} and ${max_gateflow} (cfs)`;
  const total_flow_tooltip = `Value must be between ${min_totalflow} and ${max_totalflow} (cfs)`;

  //Aggregated for XML creation 

  const eventValuesIdsUnits = {
    [head_id]:    { paramId: head_parameter_id, eventValue: headValue, eventUnit: head_unit },
    [turbine_id]: { paramId: turbine_parameter_id, eventValue: turbineflowValue, eventUnit: turbine_unit, eventQualifierId: turbine_qualifier_id },
    [gate1_id]:   { paramId: gate1_parameter_id,   eventValue: gate1Value, eventUnit: gate1_unit, eventQualifierId: gate1_qualifier_id },
    [gate2_id]:   { paramId: gate2_parameter_id,   eventValue: gate2Value, eventUnit: gate2_unit, eventQualifierId: gate2_qualifier_id },
    [gate3_id]:   { paramId: gate3_parameter_id,   eventValue: gate3Value, eventUnit: gate3_unit, eventQualifierId: gate3_qualifier_id },
    [total_flow_id]: { paramId: total_flow_parameter_id, eventValue: totalFlowValue, eventUnit: total_flow_unit, eventQualifierId: total_flow_qualifier_id },
  };
  
  const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
  const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);

  function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
    const MYPOSTURL = `/proxy/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;

    const accessToken = JSON.parse(token).access;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${accessToken}`
        },
        body: postData
    };

    async function fetchData() {
      try {
        const response = await fetch(MYPOSTURL, requestOptions);
        //console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
         //console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted", headValue, eventDate);
        }
      } catch (error) {
        // console.log(error);
        setError("ERROR:", error, ". Please try again later.");
      }
    }
    fetchData();
    //handleOpen();
    // Submit emails.
    const workflowIds = ['Daily_KIN_Entry_Confirmation', 'Daily_Current_and_Expected_Flows'];
    workflowIds.forEach(workflowId => handleRunTask(workflowId,accessToken));
  }

  return (
    <ProjectLayout>
      <Paper
        sx={{
          p: 1,
          mx: "0px",
          margin: "auto",
          marginTop: "2%",
          maxWidth: 950,
          width: "100%",
          display: "grid",
          alignItems: "center",
          //flexDirection: "column",
        }}
      >
        <h1 align='center'>{display_name} Data Entry</h1>
        <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" align='center'>
          <Grid item >
            {/* USERNAME: AUTOPOPULATED */}
            <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 200}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
          </Grid>
        </Grid>

          
          <Grid container columns={7} spacing={1} justifyContent="space-evenly" align='center' direction ="row">
            {/* DATE */}
            <Grid item xs={1} >
              <Tooltip title="Date recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 150 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Recorded"
                      disableFuture
                      minDate={minDateValidation}
                      value={eventDate}
                      dateFormat="MM-DD-YYYY"
                      format="MM-DD-YYYY"
                      onChange={e => handleDateChange(e)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Tooltip>
              {eventDateArray.map((value, index) => (
                <Tooltip key={'date_key' + index} placement="right">
                  <FormControl sx={{ m: 1, width: 150 }} required>
                    <TextField
                      //abel={`Head (ft)`}  // Label for each tooltip
                      //variant="outlined"
                      fullWidth
                      value={value.format('MM-DD-YYYY')}
                      disabled
                    />
                  </FormControl>
                </Tooltip>
              ))}
              {/* EXPECTED DATE */}
              <Tooltip title="Date Expected" placement="left">
                <FormControl sx={{ m: 1, width: 150 }} required>
                  <TextField
                    label="Expected Date"
                    variant="outlined" 
                    fullWidth
                    value={eventDateArray[eventDateArray.length - 1].format('MM-DD-YYYY')}
                    disabled
                  />
                </FormControl>
              </Tooltip>

            </Grid>
            {/* TIME */}
            <Grid item xs={1}>
              <Tooltip title="Time recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 150 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Time Recorded"
                      value={eventTime}
                      onChange={e => handleTimeChange(e)}
                      format='HH:mm:00'
                    />
                  </LocalizationProvider>
                </FormControl>
              </Tooltip>
              {eventTimeArray.map((value, index) => (
                <Tooltip key={'time_key' + index} placement="right">
                  <FormControl sx={{ m: 1, width: 150 }} required>
                    <TextField
                      //abel={`Head (ft)`}  // Label for each tooltip
                      //variant="outlined"
                      fullWidth
                      value={value.format('HH:mm:00')}
                      disabled
                    />
                  </FormControl>
                </Tooltip>
              ))}
              {/* EXPECTED TOTAL FLOW */}
              <Tooltip title="Total Flow Expected" placement="left">
                <FormControl sx={{ m: 1, width: 250 }} required>
                  <TextField
                    label="Expected Total Flow (cfs)"
                    variant="outlined" 
                    fullWidth
                    value={totalFlowValue[11]}
                    onInput={e => updateArrayValue(setTotalFlowValue, 11, e.target.value)}  // Update based on index
                    error={(totalFlowValue < min_totalflow) || (totalFlowValue >= max_totalflow)}
                    type="number"
                    inputProps={{
                      min: min_totalflow,
                      step: 1,
                      max: max_totalflow
                    }}
                    
                    />
                  </FormControl>
                  </Tooltip>

                  
                </Grid>
                {/* HEAD */}         
            <Grid item xs={0.55}>
              <Tooltip key={'head_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 90 }} required>
                  <TextField
                    
                    label="Headwater (FT)"
                    variant="outlined"
                    fullWidth
                    value="Head"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {headValue.map((value, index) => (
                <Tooltip key={'head_key' + index} title={head_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 90 }} required>
                    <TextField
                      label={`Head (FT)`}  // Label for each tooltip
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setHeadValue, index, e.target.value)}  // Update based on index
                      error={(value < min_head) || (value >= max_head)}
                      type="number"
                      inputProps={{
                        min: min_head,
                        step: 0.01,
                        max: max_head
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid>
            {/* TURBINE FLOW */}
            <Grid item xs={.85}>
              <Tooltip key={'turbineflow_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 130 }} required>
                  <TextField
                    label="Turbine Flow (cfs)"
                    variant="outlined"
                    fullWidth
                    value="Turbine Flow"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {turbineflowValue.map((value, index) => (
              <Tooltip key={'turbineflow_key' + index} title={turbineflow_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 130 }} required>
                    <TextField
                      label={`Turbine Flow (cfs)`}  // Label for each tooltip
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setTurbineFlowValue, index, e.target.value)}  // Update based on index
                      error={(value < min_turbineflow) || (value >= max_turbineflow)}
                      type="number"
                      inputProps={{
                        min: min_turbineflow,
                        step: 0.01,
                        max: max_turbineflow
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid>
            {/* GATE 1 */}
            <Grid item xs={0.55}>
              <Tooltip key={'gate1_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 90 }} required>
                  <TextField
                    label="Gate 1 (IN)"
                    variant="outlined"
                    fullWidth
                    value="Gate 1"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {gate1Value.map((value, index) => (
              <Tooltip key={'gate1_key' + index} title={gate1_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 90 }} required>
                    <TextField
                      label="Gate 1 (IN)"
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setGate1Value, index, e.target.value)}  
                      error={(value < min_gate1) || (value >= max_gate1)}
                      type="number"
                      inputProps={{
                        min: min_gate1,
                        step: 0.1,
                        max: max_gate1
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid>
            {/* GATE 2 */}
            <Grid item xs={0.4}>
              <Tooltip key={'gate2_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 90 }} required>
                  <TextField
                    label="Gate 2 (IN)"
                    variant="outlined"
                    fullWidth
                    value="Gate 2"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {gate2Value.map((value, index) => (
              <Tooltip key={'gate2_key' + index} title={gate2_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 90 }} required>
                    <TextField
                      label="Gate 2 (IN)"
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setGate2Value, index, e.target.value)}  
                      error={(value < min_gate2) || (value >= max_gate2)}
                      type="number"
                      inputProps={{
                        min: min_gate2,
                        step: 0.1,
                        max: max_gate2
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid>
            {/* GATE 3 */}
            <Grid item xs={1.2}>
              <Tooltip key={'gate3_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 90 }} required>
                  <TextField
                    label="Gate 3 (IN)"
                    variant="outlined"
                    fullWidth
                    value="Gate 3"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {gate3Value.map((value, index) => (
              <Tooltip key={'gate3_key' + index} title={gate3_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 90 }} required>
                    <TextField
                      label="Gate 3 (IN)"
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setGate3Value, index, e.target.value)}  
                      error={(value < min_gate3) || (value >= max_gate3)}
                      type="number"
                      inputProps={{
                        min: min_gate3,
                        step: 0.1,
                        max: max_gate3
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid>
            {/* GATE FLOW */}
{/*             <Grid item xs={1.5}>
              <Tooltip key={'gateflow_key_base'} title="U" placement="right">
                <FormControl sx={{ m: 1, width: 130 }} required>
                  <TextField
                    label="Gate Flow (cfs)"
                    variant="outlined"
                    fullWidth
                    value="Gate Flow"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {gateFlowValue.map((value, index) => (
              <Tooltip key={'gateflow_key' + index} title={gateflow_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 130 }} required>
                    <TextField
                      label="Gate Flow (cfs)"
                      variant="outlined"
                      fullWidth
                      value={value}
                      onInput={e => updateArrayValue(setGateFlowValue, index, e.target.value)}  
                      error={(value < min_gateflow) || (value >= max_gateflow)}
                      type="number"
                      inputProps={{
                        min: min_gateflow,
                        step: 0.1,
                        max: max_gateflow
                      }}
                    />
                  </FormControl>
                </Tooltip>
              ))}
            </Grid> */}
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: -3 }}>
            <Grid item>
              {/* SUBMIT */}
              <Button sx={{ m: 1, mt: 4, width: 350 }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                margin="normal"
                onClick={handleSubmit}
                disabled={!eventDate}
              >
                Submit
              </Button>
              <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Submitted
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                  </Typography>
                </Box>
              </Modal>
            </div>

            </Grid>
          </Grid>
        </form>
        {error && (<h3 className="error" align="center"> {error} </h3>)}
        {message && (<h3 align="center"> {message} </h3>)}

      </Paper>
    </ProjectLayout>
  );
};
