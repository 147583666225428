export default {
    "LVD": {
        "Class": "Natural",
        "DisplayName": "Lac Vieux Desert",
        "HeadMinElev": 1679.36,
        "HeadMaxElev": 1681.53,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 80.51,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        // "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 34.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    },
    "TWN": {
        "Class": "Natural",
        "DisplayName": "Twin Lakes",
        "HeadMinElev": 1680,
        "HeadMaxElev": 1683,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 81.5,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1678,
        "TailMaxElev": 1682,
        "TailBaseElev": 1600,
        "TailDefaultVal": 80,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 57.0,
        "Gate1QualifierId": "Gate_1"
    },
    "BUK": {
        "Class": "Natural",
        "DisplayName": "Buckatahpon",
        "HeadMinElev": 1638.35,
        "HeadMaxElev": 1641.52,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 40.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1635,
        "TailMaxElev": 1639,
        "TailBaseElev": 1600,
        "TailDefaultVal": 37,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 38.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    },
    "DEE": {
        "Class": "Natural",
        "DisplayName": "Long-On-Deerskin",
        "HeadMinElev": 1695,
        "HeadMaxElev": 1699,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 97,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 36.0,
        "Gate1QualifierId": "Gate_1"
    },
    "LDS": {
        "Class": "Natural",
        "DisplayName": "Little Deerskin",
        "HeadMinElev": 1640,
        "HeadMaxElev": 1643,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 41.5,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 28.5,
        "Gate1QualifierId": "Gate_1"
    },
    "SMI": {
        "Class": "Natural",
        "DisplayName": "Seven Mile",
        "HeadMinElev": 1645,
        "HeadMaxElev": 1651,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 48,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 38.0,
        "Gate1QualifierId": "Gate_1"
    },
    "NMI": {
        "Class": "Natural",
        "DisplayName": "Lower Nine Mile",
        "HeadMinElev": 1639.18,
        "HeadMaxElev": 1643.76,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 41.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1633,
        "TailMaxElev": 1637,
        "TailBaseElev": 1600,
        "TailDefaultVal": 35,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 53.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 43.0,       
        "Gate2QualifierId": "Gate_2"
    },
    "BUR": {
        "Class": "Natural",
        "DisplayName": "Burnt Rollways Chain",
        "HeadMinElev": 1622,
        "HeadMaxElev": 1626,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 24,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1616,
        "TailMaxElev": 1618,
        "TailBaseElev": 1600,
        "TailDefaultVal": 17,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 42.5,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Deep Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 85.5,
        "Gate2QualifierId": "Gate_2"
    },
    "SCP": {
        "Class": "Natural",
        "DisplayName": "Sugar Camp Chain",
        "HeadMinElev": 1592.32,
        "HeadMaxElev": 1597.82,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 95.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1599.999,
        // "TailMaxElev": 1599.999,
        // "TailBaseElev": 1500,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 82.0,
        "Gate1QualifierId": "Gate_1"
    },
    "LSG": {
        "Class": "Natural",
        "DisplayName": "Little St. Germain",
        "HeadMinElev": 1612.05,
        "HeadMaxElev": 1613.88,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 13.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 27.0,
        "Gate1QualifierId": "Gate_1"
    },
    "BSG": {
        "Class": "Natural",
        "DisplayName": "Big St. Germain",
        "HeadMinElev": 1588,
        "HeadMaxElev": 1592,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 90,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1599.999,
        // "TailMaxElev": 1599.999,
        // "TailBaseElev": 1500,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (East) Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 49.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 56.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right (West) Lift Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 54.0,
        "Gate3QualifierId": "Gate_3"
    },
    "PCL": {
        "Class": "Natural",
        "DisplayName": "Pickerel",
        "HeadMinElev": 1581.34,
        "HeadMaxElev": 1591.34,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 86.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1599.999,
        // "TailMaxElev": 1599.999,
        // "TailBaseElev": 1500,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Head Dam Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 128.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Canal Dam Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 39.0,
        "Gate2QualifierId": "Gate_2"
    },
    "SPN": {
        "Class": "Natural",
        "DisplayName": "South Pelican",
        "HeadMinElev": 1589,
        "HeadMaxElev": 1593,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 91,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1599.999,
        // "TailMaxElev": 1599.999,
        // "TailBaseElev": 1500,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 24.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 24.0,
        "Gate2QualifierId": "Gate_2"
    },
    "NPN": {
        "Class": "Natural",
        "DisplayName": "North Pelican",
        "HeadMinElev": 1566,
        "HeadMaxElev": 1570,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 68,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1563,
        "TailMaxElev": 1566,
        "TailBaseElev": 1500,
        "TailDefaultVal": 64.5,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (East) Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 46.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 46.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right (West) Lift Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 46.0,
        "Gate3QualifierId": "Gate_3"
    },
   
    "MIN": {
        "Class": "Natural",
        "DisplayName": "Minocqua Chain",
        "HeadMinElev": 1682,
        "HeadMaxElev": 1686,
        "HeadBaseElev": 1600,
        "HeadDefaultVal": 84,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1699.999,
        // "TailMaxElev": 1699.999,
        // "TailBaseElev": 1600,
        // "TailDefaultVal": 99.999,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 49.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.00,
        "Gate2Max": 49.0,
        "Gate2QualifierId": "Gate_2"
    },
    "SQL": {
        "Class": "Natural",
        "DisplayName": "Squirrel",
        "HeadMinElev": 1562,
        "HeadMaxElev": 1566,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 64,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    "TailMinElev": 1558,
        "TailMaxElev": 1562,
        "TailBaseElev": 1500,
        "TailDefaultVal": 60,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 33.5,
        "Gate1QualifierId": "Gate_1"
    },
    
    "GIL": {
        "Class": "Natural",
        "DisplayName": "Gilmore",
        "HeadMinElev": 1582,
        "HeadMaxElev": 1583,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 82.5,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",  
	    // "TailMinElev": 1599.999,
        // "TailMaxElev": 1599.999,
        // "TailBaseElev": 1500,
        // "TailDefaultVal": 99.999,
        "Gate1": false,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Gate 1",
        "Gate1Default": 99.99,
        "Gate1Min": 0.00,
        "Gate1Max": 99.99,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    }
}
