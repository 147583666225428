export default {
    "RPC": {       
        "DisplayName": "Rhinelander",
        
        "MinHead": 1554,
        "MaxHead": 1556,
        "DefaultHead": 1555,
        "HeadId": "Head",
        "HeadParameterId": "HW",   
        "HeadUnit": "FT",
        
        "MinGen": 0,
        "MaxGen": 50000,
        "DefaultGen": 0,
        "GenId": "Gen",
        "GenParameterId": "VT",
        "GenUnit": "MWH",
        "GenQualifierId": "Gen_Total",
        
        "MinLoad1": 0,
        "MaxLoad1": 1000,
        "DefaultLoad1": 0,
        "Load1Id": "Load1",
        "Load1ParameterId": "VS",
        "Load1Unit": "MW",
        "Load1QualifierId": "Load_1",

        "ExpectedLoad1Id": "ExpectedLoad1",

        
        "MinLoad2": 0,
        "MaxLoad2": 1000,
        "DefaultLoad2": 0,
        "Load2Id": "Load2",
        "Load2ParameterId": "VS",
        "Load2Unit": "MW",
        "Load2QualifierId": "Load_2",

        "ExpectedLoad2Id": "ExpectedLoad2",


        "MinLoad3": 0,
        "MaxLoad3": 1000,
        "DefaultLoad3": 0,
        "Load3Id": "Load3",
        "Load3ParameterId": "VS",
        "Load3Unit": "MW",
        "Load3QualifierId": "Load_3",

        "ExpectedLoad3Id": "ExpectedLoad3",

        "MinEastGate": 0,
        "MaxEastGate": 100,
        "DefaultEastGate": 0,
        "EastGateId": "Gate1", 
        "EastGateParameterId": "NO", 
        "EastGateUnit": "IN",
        "EastGateQualifierId": "Gate_1",

        "ExpectedEastGateId": "ExpectedEastGate",

        "MinWestGate": 0,
        "MaxWestGate": 100,
        "DefaultWestGate": 0,
        "WestGateId": "Gate2",
        "WestGateParameterId": "NO",
        "WestGateUnit": "IN",
        "WestGateQualifierId": "Gate_2",

        "ExpectedWestGateId": "ExpectedWestGate",

        "MinTaintorGate": 0,
        "MaxTaintorGate": 100,
        "DefaultTaintorGate": 0,
        "TaintorGateId": "Gate3",
        "TaintorGateParameterId": "NO",
        "TaintorGateUnit": "IN",
        "TaintorGateQualifierId": "Gate_3",
        "ExpectedTaintorGateId": "ExpectedTaintorGate",

        
        "MinTotalFlow": 0,
        "MaxTotalFlow": 100000,
        "DefaultTotalFlow": 0,
        "FlowId": "Flow",
        "FlowParameterId": "QT",
        "FlowUnit": "CFS",

        "ExpectedTotalFlowId": "ExpectedFlow",
        "ExpectedTotalFlowQualifierId": "Expected",

    }
}
