export default {
    "DAY": {       
        "DisplayName": "DuBay",
     
        "MinTotalFlow": 0,
        "MaxTotalFlow": 100000,
        "DefaultTotalFlow": 0,
        "FlowId": "Flow",
        "FlowParameterId": "QT",
        "FlowUnit": "CFS",

        "ExpectedTotalFlowId": "ExpectedFlow",
        "ExpectedTotalFlowQualifierId": "Expected",

    }
}
