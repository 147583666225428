import { useState, useEffect, useRef } from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import PlaceIcon from '@mui/icons-material/Place';
import { Precipitation } from "../Forms/Precipitation";
import { isPrecipitationGroup } from "../Helpers/groupCheck";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export const MyPrecipitation= () => {
  const [precipitation, setPrecipitation] = useState("");
  const [user, setUser] = useState(null);
  const prevPrecipitationRef = useRef("");
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const userObj = JSON.parse(window.localStorage.getItem("afs_user"));

    if (userObj) {
      setUser(userObj);
    }
  }, []);

  useEffect(() => {
    prevPrecipitationRef.current = precipitation;
  }, [precipitation]);

  const handleBackToSummaryPg = () => {
    setPrecipitation("");
  };
    return (
    <ProjectLayout>
      {!precipitation && (
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            marginTop: "5%",
            maxWidth: 400,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Precipitation Locations</Typography>
          {user?.groups.filter((group) => isPrecipitationGroup(group.name) )
              .map((group) => {
            const groupName =
              group.name.charAt(0).toUpperCase() + group.name.slice(1);
            let groupNameDisplay = groupName.replace("EP3", "Big Eau Pleine Reservoir");
            groupNameDisplay = groupNameDisplay.replace("WOW", "Willow Reservoir");
            groupNameDisplay = groupNameDisplay.replace("RIC", "Rice Reservior");
            groupNameDisplay = groupNameDisplay.replace("WR1", "Wisconsin Rapids");

            return (
              <IconButton
                key={group.id}
                onClick={() => {
                  prevPrecipitationRef.current = groupName;
                  setPrecipitation(groupName);
                  navigate(`/precipitation/${group.name}`);

                }}
              >
                <PlaceIcon /> {groupNameDisplay}
              </IconButton>
            );
          })}
        </Paper>
      )}

      {precipitation && (
        <Precipitation precipitation={precipitation}/>
        //<NaturalLakes precipitation={precipitation}/>

      )}
    </ProjectLayout>
  );
};