import { Header } from "./Header";
// import { Footer } from "./Footer";
import "./ProjectLayout.css";

export const ProjectLayout = ({ children }) => {
  return (
    <>
      <Header />  {/* ✅ Header always renders, no matter what */}
      <div>
        {children ? children : <p>Loading...</p>} {/* ✅ If children fail, prevent full crash */}
      </div>
    </>
  );
};