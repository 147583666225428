import * as React from 'react';
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '../../Utils/MyAdapterDayJs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
// import { REACT_APP_FEWS_PREFIX } from "../../Utils/constants";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_natlakes from './valid_input_natlakes' ;
import { myCustomEncoder, generateXmlBodyStr } from './CustomEncoder';
import "./Forms.css";
import valid_input_bigfive from './valid_input_bigfive';
import { useParams } from "react-router-dom";
import { ProjectLayout } from "../Layouts/ProjectLayout";

// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const BigFive = () => {
  // USERNAME
  const { user, token } = useAuth();
  const { bigFiveLake } = useParams();

  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];
  
  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    return formattedDate
  };
  const dateNowParser = (nowDateString) => {
    const nowFormattedDate = nowDateString.format('YYYY-MM-DD');
    return nowFormattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    return formattedTime
  };
  const timeNowParser = (dateString) => {
    const nowFormattedTime = dateString.format('HH:00:00');
    return nowFormattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  
  const [locationId, setLocationId] = useState(bigFiveLake);
  const [paramId, setParamId] = useState('HW');
  const [nowDate, setNowDate] = useState(dateNowParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeNowParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS
  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:mm:00') : null; //'HH:mm:00'
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');
  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');

  // VALIDATION LOGIC
  //Change valid input for natural lake and big five?
  const input_ranges = valid_input_bigfive[locationId];
  //const input_ranges = valid_input_natlakes[locationId];
  const display_name = input_ranges.DisplayName;
  const default_head_value = input_ranges.HeadDefaultVal;
  const base_head_elev = input_ranges.HeadBaseElev;
  const min_head_val_full = input_ranges.HeadMinElev;
  const max_head_val_full = input_ranges.HeadMaxElev;
  const min_head_val = (min_head_val_full - base_head_elev).toPrecision(2);
  const max_head_val = (max_head_val_full - base_head_elev).toPrecision(2);
  const location_tooltip = `${display_name} (${locationId})`;

  const default_tail_value = input_ranges.TailDefaultVal;
  const base_tail_elev     = input_ranges.TailBaseElev;
  const min_tail_val_full  = input_ranges.TailMinElev;
  const max_tail_val_full  = input_ranges.TailMaxElev;
  const min_tail_val = (min_tail_val_full - base_tail_elev).toPrecision(2);
  const max_tail_val = (max_tail_val_full - base_tail_elev).toPrecision(2);

  const head_tooltip = `Value must be between ${min_head_val} and ${max_head_val} feet (required).`;
  const tail_tooltip = `Value must be between ${min_tail_val} and ${max_tail_val} feet (required).`;
  const include_head = input_ranges.Head;
  const include_tail = input_ranges.Tail;
  const head_id = input_ranges.HeadId;
  const tail_id = input_ranges.TailId;
  const head_unit = input_ranges.HeadUnit;
  const tail_unit = input_ranges.TailUnit;
  const head_parameter_id = input_ranges.HeadParameterId;
  const tail_parameter_id = input_ranges.TailParameterId;

  // GATES VALIDATION 
  const include_gate1 = input_ranges.Gate1;
  const gate1_description = input_ranges.Gate1DisplayName+ " (IN)";
  const gate1_min = input_ranges.Gate1Min;
  const gate1_max = input_ranges.Gate1Max;
  const gate1_default = input_ranges.Gate1Default;
  const gate1_id = input_ranges.Gate1Id;
  const gate1_unit = input_ranges.Gate1Unit;
  const gate1_qualifier_id = input_ranges.Gate1QualifierId;
  const gate1_parameter_id = input_ranges.Gate1ParameterId;
  const gate1_tooltip = `Value must be between ${gate1_min} and ${gate1_max} inches (required).`;

  const include_gate2 = input_ranges.Gate2;
  const gate2_description = input_ranges.Gate2DisplayName+ " (IN)";
  const gate2_min = input_ranges.Gate2Min;
  const gate2_max = input_ranges.Gate2Max;
  const gate2_default = input_ranges.Gate2Default;
  const gate2_id = input_ranges.Gate2Id;
  const gate2_unit = input_ranges.Gate2Unit;
  const gate2_qualifier_id = input_ranges.Gate2QualifierId;
  const gate2_parameter_id = input_ranges.Gate2ParameterId;
  const gate2_tooltip = `Value must be between ${gate2_min} and ${gate2_max} inches (required).`;

  const include_gate3 = input_ranges.Gate3;
  const gate3_description = input_ranges.Gate3DisplayName+ " (IN)";
  const gate3_min = input_ranges.Gate3Min;
  const gate3_max = input_ranges.Gate3Max;
  const gate3_default = input_ranges.Gate3Default;
  const gate3_id = input_ranges.Gate3Id;
  const gate3_unit = input_ranges.Gate3Unit;
  const gate3_qualifier_id = input_ranges.Gate3QualifierId;
  const gate3_parameter_id = input_ranges.Gate3ParameterId;
  const gate3_tooltip = `Value must be between ${gate3_min} and ${gate3_max} inches (required).`;

  const include_gate4 = input_ranges.Gate4;
  const gate4_description = input_ranges.Gate4DisplayName+ " (IN)";
  const gate4_min = input_ranges.Gate4Min;
  const gate4_max = input_ranges.Gate4Max;
  const gate4_default = input_ranges.Gate4Default;
  const gate4_id = input_ranges.Gate4Id;
  const gate4_unit = input_ranges.Gate4Unit;
  const gate4_qualifier_id = input_ranges.Gate4QualifierId;
  const gate4_parameter_id = input_ranges.Gate4ParameterId;
  const gate4_tooltip = `Value must be between ${gate4_min} and ${gate4_max} inches (required).`;

  const include_gate5 = input_ranges.Gate5;
  const gate5_description = input_ranges.Gate5DisplayName+ " (IN)";
  const gate5_min = input_ranges.Gate5Min;
  const gate5_max = input_ranges.Gate5Max;
  const gate5_default = input_ranges.Gate5Default;
  const gate5_id = input_ranges.Gate5Id;
  const gate5_unit = input_ranges.Gate5Unit;
  const gate5_qualifier_id = input_ranges.Gate5QualifierId;
  const gate5_parameter_id = input_ranges.Gate5ParameterId;
  const gate5_tooltip = `Value must be between ${gate5_min} and ${gate5_max} inches (required).`;

  const [eventHeadValue, setEventHeadValue] = useState(default_head_value);
  const [eventTailValue, setEventTailValue] = useState(default_tail_value);
  const [eventGate1Value, setEventGate1Value] = useState(gate1_default);
  const [eventGate2Value, setEventGate2Value] = useState(gate2_default);
  const [eventGate3Value, setEventGate3Value] = useState(gate3_default);
  const [eventGate4Value, setEventGate4Value] = useState(gate4_default);
  const [eventGate5Value, setEventGate5Value] = useState(gate5_default);

  //Aggregated for XML creation 
const eventValuesIdsUnits = {
  [head_id]: { paramId: head_parameter_id, eventValue: eventHeadValue, eventUnit: head_unit },
  [tail_id]: { paramId: tail_parameter_id, eventValue: eventTailValue, eventUnit: tail_unit },
  [gate1_id]: { paramId: gate1_parameter_id, eventValue: eventGate1Value, eventUnit: gate1_unit, eventQualifierId: gate1_qualifier_id },
  [gate2_id]: { paramId: gate2_parameter_id, eventValue: eventGate2Value, eventUnit: gate2_unit, eventQualifierId: gate2_qualifier_id },
  [gate3_id]: { paramId: gate3_parameter_id, eventValue: eventGate3Value, eventUnit: gate3_unit, eventQualifierId: gate3_qualifier_id },
  [gate4_id]: { paramId: gate4_parameter_id, eventValue: eventGate4Value, eventUnit: gate4_unit, eventQualifierId: gate4_qualifier_id },
  [gate5_id]: { paramId: gate5_parameter_id, eventValue: eventGate5Value, eventUnit: gate5_unit, eventQualifierId: gate5_qualifier_id },
  };
  
  const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
  const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);

function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
    const MYPOSTURL = `/proxy/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;

    const accessToken = JSON.parse(token).access;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Bearer ${accessToken}`
        },
        body: postData
    };
 
    async function fetchData() {
      try {
        const response = await fetch( MYPOSTURL, requestOptions );
        // console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
        // console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted", eventHeadValue, eventDate);
        } 
      } catch (error) {
          // console.log(error);
          setError("ERROR:",error,". Please try again later.");
      }
    }
    fetchData();
    // handleOpen();
  }
    


  return(
    <ProjectLayout>
    <div>
      <Paper
        sx={{
            p: 2,
            margin: "auto",
            marginTop: "5%",
            maxWidth: 400,
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
        }}
        >
        <h1 align='center'>{display_name} Data Entry</h1>
        {/* <h2 align='center'>{display_name}</h2> */}
        <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" align='center'>
          <Grid item>
            {/* USERNAME: AUTOPOPULATED */}
            <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 350}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
            {/* LOCATION - AUTOPOPULATED BASED ON PREVIOUS SELECTION*/}
            <Tooltip title={location_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350 }} required>
                <TextField
                    id="location"
                    label="Location"
                    variant="filled"
                    fullWidth
                    value={bigFiveLake}
                    disabled
                  />
              </FormControl>
            </Tooltip>
            {/* DATE */}
            <Tooltip title="Date recorded (required)" placement="right">
              <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date Recorded"
                    disableFuture
                    minDate={minDateValidation}
                    value={eventDate}
                    dateFormat="MM-DD-YYYY" 
                    format="MM-DD-YYYY"
                    onChange={e=>handleDateChange(e)} 
                  /> 
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* TIME */}
            <Tooltip title="Time recorded (required)" placement="right">
              <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker  
                    label="Time Recorded"
                    value={eventTime}
                    onChange={e=>handleTimeChange(e)} 
                    format='HH:mm:00'
                  />
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* HEAD */}
            {include_head &&
              <Tooltip title={head_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label="Headwater (FT)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventHeadValue}
                      onInput={ e=>setEventHeadValue(e.target.value)}
                      // error={hasError}
                      error={(eventHeadValue < min_head_val) || (eventHeadValue >= max_head_val)}
                      inputProps={{
                        min: min_head_val, 
                        step: 0.01,
                        max: max_head_val
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            {/* TAIL */}
            {include_tail &&
              <Tooltip title={tail_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label="Tailwater (FT)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventTailValue}
                      onInput={ e=>setEventTailValue(e.target.value)}
                      // error={hasError}
                      error={(eventTailValue < min_tail_val) || (eventTailValue >= max_tail_val)}
                      inputProps={{
                        min: min_tail_val, 
                        step: 0.01,
                        max: max_tail_val
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            {/* GATE1 */}
            {include_gate1 &&
              <Tooltip title={gate1_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label={gate1_description}
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventGate1Value}
                      onInput={ e=>setEventGate1Value(e.target.value)}
                      // error={hasError}
                      error={(eventGate1Value < gate1_min) || (eventGate1Value >= gate1_max)}
                      inputProps={{
                        min: gate1_min, 
                        step: 0.1,
                        max: gate1_max
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
             {/* GATE2 */}
             {include_gate2 &&
              <Tooltip title={gate2_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label={gate2_description}
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventGate2Value}
                      onInput={ e=>setEventGate2Value(e.target.value)}
                      // error={hasError}
                      error={(eventGate2Value < gate2_min) || (eventGate2Value >= gate2_max)}
                      inputProps={{
                        min: gate2_min, 
                        step: 0.1,
                        max: gate2_max
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            {/* GATE3 */}
            {include_gate3 &&
              <Tooltip title={gate3_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label={gate3_description}
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventGate3Value}
                      onInput={ e=>setEventGate3Value(e.target.value)}
                      // error={hasError}
                      error={(eventGate3Value < gate3_min) || (eventGate3Value >= gate3_max)}
                      inputProps={{
                        min: gate3_min, 
                        step: 0.1,
                        max: gate3_max
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            {/* GATE4 */}
             {include_gate4 &&
              <Tooltip title={gate4_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label={gate4_description}
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventGate4Value}
                      onInput={ e=>setEventGate4Value(e.target.value)}
                      // error={hasError}
                      error={(eventGate4Value < gate4_min) || (eventGate4Value >= gate4_max)}
                      inputProps={{
                        min: gate4_min, 
                        step: 0.1,
                        max: gate4_max
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            {/* GATE5 */}
            {include_gate5 &&
              <Tooltip title={gate5_tooltip} placement="right">
                  <FormControl sx={{ m: 1, width: 350 }} required>
                    <TextField
                      label={gate5_description}
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={eventGate5Value}
                      onInput={ e=>setEventGate5Value(e.target.value)}
                      // error={hasError}
                      error={(eventGate5Value < gate5_min) || (eventGate5Value >= gate5_max)}
                      inputProps={{
                        min: gate5_min, 
                        step: 0.1,
                        max: gate5_max
                      }}
                    />
                </FormControl>
              </Tooltip>
            }
            
            {/* SUBMIT */}
            <Button  sx={{m: 1,  mt: 4, width: 350}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              margin="normal"
              onClick={handleSubmit}
              disabled={!eventDate}

              >
                  Submit
            </Button>

            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Submitted
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                  </Typography>
                </Box>
              </Modal>
            </div>
        
          </Grid>
        </Grid>
         
        </form>
        {error && (<h3 className="error" align="center"> {error} </h3>)}
        {message && (<h3 align="center"> {message} </h3>)}
      </Paper>
    </div>
    </ProjectLayout>
  );
};

