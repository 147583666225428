export default {
    "EP3":
        {       
            "DisplayName": "Big Eau Pleine Reservoir",
            "Precipitation": true,
            "MinPrecip": 0,
            "MaxPrecip": 36,
            "DefaultPrecip": 0,
            "PrecipId": "Precip",
            "PrecipParameterId": "P.obs",
            "PrecipUnit": "IN",
            "NewSnow": false,
            "NewSnowDisplayName" : "New Snow",
            "MinNewSnow": 0,
            "MaxNewSnow": 36,
            "DefaultNewSnow": 0,
            "NewSnowId": "NewSnow",
            "NewSnowParameterId": "SF",
            "NewSnowUnit": "IN",
            "SnowDepth": false,
            "SnowDepthDisplayName" : "Snow Depth",
            "MinSnowDepth": 0,
            "MaxSnowDepth": 36,
            "DefaultSnowDepth": 0,
            "SnowDepthId": "SnowDepth",
            "SnowDepthParameterId": "SD",
            "SnowDepthUnit": "IN"
        },
        "WOW":
        {       
            "DisplayName": "Willow Reservoir",
            "Precipitation": true,
            "MinPrecip": 0,
            "MaxPrecip": 36,
            "DefaultPrecip": 0,
            "PrecipId": "Precip",
            "PrecipParameterId": "P.obs",
            "PrecipUnit": "IN",
            "NewSnow": false,
            "NewSnowDisplayName" : "New Snow",
            "MinNewSnow": 0,
            "MaxNewSnow": 36,
            "DefaultNewSnow": 0,
            "NewSnowId": "NewSnow",
            "NewSnowParameterId": "SF",
            "NewSnowUnit": "IN",
            "SnowDepth": false,
            "SnowDepthDisplayName" : "Snow Depth",
            "MinSnowDepth": 0,
            "MaxSnowDepth": 36,
            "DefaultSnowDepth": 0,
            "SnowDepthId": "SnowDepth",
            "SnowDepthParameterId": "SD",
            "SnowDepthUnit": "IN"
        },
        "RIC":
        {       
            "DisplayName": "Rice Reservior",
            "Precipitation": true,
            "MinPrecip": 0,
            "MaxPrecip": 36,
            "DefaultPrecip": 0,
            "PrecipId": "Precip",
            "PrecipParameterId": "P.obs",
            "PrecipUnit": "IN",
            "NewSnow": false,
            "NewSnowDisplayName" : "New Snow",
            "MinNewSnow": 0,
            "MaxNewSnow": 36,
            "DefaultNewSnow": 0,
            "NewSnowId": "NewSnow",
            "NewSnowParameterId": "SF",
            "NewSnowUnit": "IN",
            "SnowDepth": false,
            "SnowDepthDisplayName" : "Snow Depth",
            "MinSnowDepth": 0,
            "MaxSnowDepth": 36,
            "DefaultSnowDepth": 0,
            "SnowDepthId": "SnowDepth",
            "SnowDepthParameterId": "SD",
            "SnowDepthUnit": "IN"
        },
        "WR1":
        {       
            "DisplayName": "Wisconsin Rapids",
            "Precipitation": true,
            "MinPrecip": 0,
            "MaxPrecip": 36,
            "DefaultPrecip": 0,
            "PrecipId": "Precip",
            "PrecipParameterId": "P.obs",
            "PrecipUnit": "IN",
            "NewSnow": true,
            "NewSnowDisplayName" : "New Snow",
            "MinNewSnow": 0,
            "MaxNewSnow": 36,
            "DefaultNewSnow": 0,
            "NewSnowId": "NewSnow",
            "NewSnowParameterId": "SF",
            "NewSnowUnit": "IN",
            "SnowDepth": true,
            "SnowDepthDisplayName" : "Snow Depth",
            "MinSnowDepth": 0,
            "MaxSnowDepth": 120,
            "DefaultSnowDepth": 0,
            "SnowDepthId": "SnowDepth",
            "SnowDepthParameterId": "SD",
            "SnowDepthUnit": "IN"
        }

    }