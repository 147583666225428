export default {
    "RAI": {
        "Class": "Big5",
        "DisplayName": "Rainbow Reservoir",
        "HeadMinElev": 1575.05,
        "HeadMaxElev": 1597.05,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 86.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",        
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",        
        "TailUnit": "FT",  
	    "TailMinElev": 1569,
        "TailMaxElev": 1595,
        "TailBaseElev": 1500,
        "TailDefaultVal": 72,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (East) Deep Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 169.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Left (East) Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 197.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Center Roll Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 197.0,
        "Gate3QualifierId": "Gate_3",
        "Gate4": true,
        "Gate4Id": "Gate4",
        "Gate4ParameterId": "NO",
        "Gate4Unit": "IN",
        "Gate4DisplayName": "Right (West) Roll Gate (Gate 4)",
        "Gate4Default": 12.0,
        "Gate4Min": 0.0,
        "Gate4Max": 197.0,
        "Gate4QualifierId": "Gate_4",
        "Gate5": true,
        "Gate5Id": "Gate5",
        "Gate5ParameterId": "NO",
        "Gate5Unit": "IN",
        "Gate5DisplayName": "Right (West) Deep Gate (Gate 5)",
        "Gate5Default": 12.0,
        "Gate5Min": 0.0,
        "Gate5Max": 196.0,
        "Gate5QualifierId": "Gate_5"
    },
    "WOW": {
        "Class": "Big5",
        "DisplayName": "Willow Reservoir",
        "HeadMinElev": 1510.85,
        "HeadMaxElev": 1529.35,
        "HeadBaseElev": 1500,
        "HeadDefaultVal": 20.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",        
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",        
        "TailUnit": "FT",  
	    "TailMinElev": 1502,
        "TailMaxElev": 1520,
        "TailBaseElev": 1500,
        "TailDefaultVal": 11,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (North) Deep Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 137.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 125.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right (South) Roll Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 137.0,
        "Gate3QualifierId": "Gate_3"
    },
    "RIC": {
        "Class": "Big5",
        "DisplayName": "Rice Reservior",
        "HeadMinElev": 1448.25,
        "HeadMaxElev": 1463.25,
        "HeadBaseElev": 1400,
        "HeadDefaultVal": 55.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",        
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",        
        "TailUnit": "FT",  
	    "TailMinElev": 1445,
        "TailMaxElev": 1462,
        "TailBaseElev": 1400,
        "TailDefaultVal": 55,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (South) Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 180.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 180.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": false,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right (North) Dep Bay (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 180.0,
        "Gate3QualifierId": "Gate_3",
    },
    "SPD": {
        "Class": "Big5",
        "DisplayName": "Spirit Reservoir",
        "HeadMinElev": 1420.88,
        "HeadMaxElev": 1437.88,
        "HeadBaseElev": 1400,
        "HeadDefaultVal": 29.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",        
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",        
        "TailUnit": "FT",  
	    "TailMinElev": 1419,
        "TailMaxElev": 1432,
        "TailBaseElev": 1400,
        "TailDefaultVal": 25,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (North) Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 238.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right (South) Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 238.0,
        "Gate2QualifierId": "Gate_2"
    },
    
    "EP3": {
        "Class": "Big5",
        "DisplayName": "Big Eau Pleine Reservoir",
        "HeadMinElev": 1118,
        "HeadMaxElev": 1145.43,
        "HeadBaseElev": 1100,
        "HeadDefaultVal": 31.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",        
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",        
        "TailUnit": "FT",  
	    "TailMinElev": 1110,
        "TailMaxElev": 1135,
        "TailBaseElev": 1100,
        "TailDefaultVal": 20,
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left (North) Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 238.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 218.5,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right (South) Roll Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 238.0,
        "Gate3QualifierId": "Gate_3",
        "Gate4": true,
        "Gate4Id": "Gate4",
        "Gate4ParameterId": "NO",
        "Gate4Unit": "IN",
        "Gate4DisplayName": "Sluice Gate (Gate 4)",
        "Gate4Default": 12.0,
        "Gate4Min": 0.0,
        "Gate4Max": 72.0,
        "Gate4QualifierId": "Gate_4"
    }
}
