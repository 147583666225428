import { useState, useEffect, useRef } from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import PlaceIcon from '@mui/icons-material/Place';
import { BigFive } from "../Forms/BigFive";
import { isBigFiveGroup } from "../Helpers/groupCheck";
import { useNavigate } from "react-router-dom"; 

export const MyBigFive = () => {
  const [bigFiveLake, setBigFiveLake] = useState("");
  const [user, setUser] = useState(null);
  const prevBigFiveLakeRef = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    const userObj = JSON.parse(window.localStorage.getItem("afs_user"));

    if (userObj) {
      setUser(userObj);
    }
  }, []);

  useEffect(() => {
    prevBigFiveLakeRef.current = bigFiveLake;
  }, [bigFiveLake]);

  const handleBackToSummaryPg = () => {
    setBigFiveLake("");
  };
    return (
    <ProjectLayout>
      {!bigFiveLake && (
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            marginTop: "5%",
            maxWidth: 400,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Big Five Locations</Typography>
          {user?.groups.filter((group) => isBigFiveGroup(group.name) )
              .map((group) => {
            const groupName =
              group.name.charAt(0).toUpperCase() + group.name.slice(1);
            // Big 5 lakes
            let groupNameDisplay = groupName.replace("RAI", "Rainbow Reservoir");
            groupNameDisplay = groupNameDisplay.replace("RIC", "Rice Reservoir");
            groupNameDisplay = groupNameDisplay.replace("SPD", "Spirit Reservoir");
            groupNameDisplay = groupNameDisplay.replace("WOW", "Willow Reservoir");
            groupNameDisplay = groupNameDisplay.replace("EP3", "Big Eau Pleine Reservoir");

            return (
              <IconButton
                key={group.id}
                onClick={() => {
                  prevBigFiveLakeRef.current = groupName;
                  //setBigFiveLake(groupName);
                  navigate(`/big-five/${group.name}`);

                }}
              >
                <PlaceIcon /> {groupNameDisplay}
              </IconButton>
            );
          })}
        </Paper>
      )}

      {bigFiveLake && (
        <BigFive bigFiveLake={bigFiveLake}/>
        //<NaturalLakes bigFiveLake={bigFiveLake}/>

      )}
    </ProjectLayout>
  );
};